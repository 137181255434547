<template>
  <div class="main">
    <agent-info-comp></agent-info-comp>

    <agent-sub-title>머니이동</agent-sub-title>


    <div class="gameinfo">
      <div style="width: 100%;text-align: center;margin: 10px">
        <h5 style="text-align: center">보유금액</h5>
        <span style="color: #3a8ee6">{{ $store.state.agent.agentInfo.cash|comma }}</span>
      </div>

      <div style="width: 100%;text-align: center">
        <el-radio size="mini" v-model="move.movetype" :label="agentConst.MOVE_CASH_2_USER" border>회원주기
        </el-radio>
        <el-radio size="mini" v-model="move.movetype" :label="agentConst.MOVE_CASH_2_AGENT" border>총판주기
        </el-radio>
      </div>
      <div style="width: 100%;margin: 10px 0px;text-align: center">
        <el-radio size="mini" v-model="move.flowType" :label="agentConst.FLOWTYPE_ADD" border><i
            class="fa fa-plus-square"> 추가</i>
        </el-radio>
        <el-radio size="mini" v-model="move.flowType" :label="agentConst.FLOWTYPE_MINUS" border><i
            class="fa fa-minus"> 차감</i>
        </el-radio>
      </div>


      <div style="width:100%;display: flex;justify-content: center;align-items: center;margin: 10px 0px">
        <div style="width: 34%">
          <h5 style="text-align: center">닉네임</h5>
          <el-input size="mini" v-model="move.nickname"></el-input>
        </div>
      </div>

      <div style="width:100%;display: flex;justify-content: center;align-items: center;margin: 10px 0px">
        <div style="width: 34%">
          <h5 style="text-align: center">금액</h5>
          <el-input size="mini" v-model="move.cash"></el-input>
        </div>
      </div>
      <div style="width:100%;display: flex;justify-content: center;align-items: center;margin: 10px 0px">
        <el-button @click="movenow()">저장하기</el-button>
        <el-button @click="outerVisible = false">취소</el-button>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">

    </div>
  </div>

</template>

<script>

import {Loading} from 'element-ui';
import AgentSubTitle from "../../components/agent/AgentSubTitle";
import {agentMixin} from "../../common/agent/agentMixin";
import {doLogin, getAgentById, moveagentmoney} from "../../network/agent/commonRequest";
import AgentInfoComp from "../../components/agent/AgentInfoComp";

export default {
  name: "AgentMoveCash",
  components: {
    AgentInfoComp,
    AgentSubTitle,
  },
  mixins: [agentMixin],
  data() {
    return {
      move: {},
      fullscreenLoading: false,
    }
  },
  methods: {
    movenow() {
      let loadingInstance = Loading.service({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      moveagentmoney(this.move).then(res => {
        loadingInstance.close();
        if (res.data.success) {
          this.$message.success("머니이동이 완료되였습니다");
          getAgentById(-1).then(res => {
            this.$store.state.agent.agentInfo = res.data.data;
          })
        } else {
          this.$message.error(res.data.msg);
        }
      })

    }
  },
  created() {
    if (this.$store.state.agent.agentInfo.moneymoveable == 0) {
      this.$router.push('agent_main_statistic')
    }
  }
}
</script>

<style scoped>
.table50 {
  border: 2px solid #0c0e0e;
  margin-top: 5px;
  font-weight: 600;
}

.data {
  width: 100%;
  height: calc(100vh - 185px);
  overflow-y: hidden;
  align-items: flex-start;
}

.gameinfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  background-color: #b3d8ff;
  padding: 10px;

}

.g1 {
  margin-top: 5px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #b3d8ff;
  padding: 5px;
  border-radius: 3px;
}

</style>